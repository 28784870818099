import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import React from 'react';

import { AuthProvider } from '../contexts/AuthContext';
import PrivateRoute from './PrivateRoute';

import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Places from '../pages/Places';
import Place from '../pages/Place';
import Menu from '../pages/Menu';
import Orders from '../pages/Orders';
import MenuSettings from '../pages/MenuSettings';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/login' element={<Login/>}/>

          <Route exact path='/register' element={<Register/>}/>


          <Route exact path='/menu/:id/:table' element={<Menu/>}/>

          <Route exact path='/places/:id' element={<PrivateRoute element={<Place/>}/>}/>

          <Route exact path='/places' element={<PrivateRoute element={<Places/>}/>}/>

          <Route exact path='/places/:id/orders' element={<PrivateRoute element={<Orders/>}/>}/>

          <Route exact path='/places/:id/settings' element={<PrivateRoute element={<MenuSettings/>}/>}/>

{/*          <PrivateRoute exact path='/places/:id'>
            <Place/>
          </PrivateRoute>
          <PrivateRoute exact path='/places' >
            <Places/>
          </PrivateRoute>
          <PrivateRoute exact path='/places/:id/orders'>
            <Orders/>
          </PrivateRoute>
          <PrivateRoute exact path='/places/:id/settings'>
            <MenuSettings/>
          </PrivateRoute>*/}
        </Routes>
      </BrowserRouter>
      <ToastContainer/>
    </AuthProvider>
  )
}

export default App;