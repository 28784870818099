import { Row, Col, Button } from "react-bootstrap";
import React from "react";
import styled from "styled-components";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import clsx from "clsx";

function formatXOF({
                       amount,
                       showCurrency = true,
                   }) {

    const formatter = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "XOF",
    });

    // Extracting the formatted number without currency symbol
    const formattedNumberWithCurrency = formatter.format(Number(amount));
    const formattedNumberWithoutCurrency = formattedNumberWithCurrency
        .replace(/F\sCFA/g, "")
        .trim();

    return showCurrency
        ? formattedNumberWithCurrency
        : formattedNumberWithoutCurrency;
}

const Container = styled.div`
  border-radius: 5px;
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  > div:first-child {
    width: 40%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-size: cover;
  }
  > div:last-child {
    padding: 15px 20px;
    min-height: 150px;
  }
`;

const MyContainer = styled.div`
  display: flex;
  justify: between;
`;

const MenuItem = ({ item, onEdit, onRemove, onOrder, color }) => (
   <>
       {/*        <Container active={item.is_available}>
             Change from <Col> to <Row>

            <Row className="d-flex flex-column justify-content-between w-100">
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h4 className="mb-0">
                            <b>{item.name}</b>
                        </h4>
                        <div>
                            { onEdit ? (
                                <Button variant="link" onClick={onEdit}>
                                    <BiEdit size={20} />
                                </Button>
                            ) : null }

                            { onRemove ? (
                                <Button variant="link" onClick={onRemove}>
                                    <AiOutlineDelete size={20} color="red" />
                                </Button>
                            ) : null }
                        </div>
                    </div>
                    <p className="mb-4">{item.description}</p>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                    <div>
                        <h5 className="mb-0 text-standard">
                            <b style={{ color }}>{item.price} FCFA</b>
                        </h5>

                        {onOrder ? (
            <Button
              variant="standard"
              style={{ backgroundColor: color }}
              className="mt-2"
              size="sm"
              onClick={() => onOrder(item)}
            >
              {!item.quantity ? "Add to shopping cart" : `Add one more (${item.quantity})`}
            </Button>
          ) : null}
                    </div>

                    {!item.is_available ? (<small className="text-secondary">Pas disponible</small>) : null}

                </div>
            </Row>
        </Container>*/}

       <div
           className={clsx(!item.is_available && "opacity-60", "flex justify-content-between  bg-white p-3 mb-2 rounded w-full")}
       >
           <div className="">
               {/* Title and Subtitle */}
               <div style={{fontSize: 23}}>
                   <span style={{fontFamily: "jeanluc-bla"}}>{item.name}</span>
                   <p
                       style={{
                           color: "#E83089",
                           fontFamily: "jeanluc-thi",
                       }}
                   >
                       {item.description}
                   </p>
                   <p>
                       {!item.is_available ? (
                           <small className="text-secondary text-sm">Pas disponible</small>) : null}

                   </p>
                   <div className="flex flex-col items-start">
                       <div className="">
                           {onEdit ? (
                               <Button variant="link" onClick={onEdit}>
                                   <BiEdit size={20}/>
                               </Button>
                           ) : null}

                           {onRemove ? (
                               <Button variant="link" onClick={onRemove}>
                                   <AiOutlineDelete size={20} color="red"/>
                               </Button>
                           ) : null}
                       </div>

                   </div>
               </div>
           </div>
           {/* Price */}
           <div>
               <div className="items-end">
                   <p
                       style={{
                           color: "#E83089",
                           fontFamily: "jeanluc-bla",
                           fontSize: 24
                       }}
                   >
                       {formatXOF({amount: item?.price ?? "0"})}
                   </p>
               </div>
           </div>
       </div>
   </>


);

export default MenuItem;
