import {Button, Col, Form, Row, Card, Spinner, InputGroup} from "react-bootstrap"
import { useState, useEffect, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import AuthContext from '../contexts/AuthContext';
import {FaEye, FaEyeSlash} from "react-icons/fa6";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.token) {
      navigate('../places', { replace: true });
    }
  });

  const onClick = () => {
    auth.signIn(username, password, () => navigate("../places", { replace: true }));
  };

  return (
    <MainLayout>
      <Row className="justify-content-center">
        <Col lg={6} md={8}>
          <Card>
            <Card.Body>
              <h3 className="text-center">
                <b>CONNEXION</b>
              </h3>

              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Mot de passe</Form.Label>
                <InputGroup>
                  <Form.Control
                      className=""
                      type={viewPassword ? "text": "password"}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroup.Text className={"cursor-pointer"} onClick={() => setViewPassword(prevState => !prevState)}>
                    {viewPassword ? <FaEye /> : <FaEyeSlash/>}
                  </InputGroup.Text>
                </InputGroup>

              </Form.Group>

              <Button variant="standard" block="true" onClick={onClick} disabled={auth.loading}>
                {
                  auth.loading ? (
                    <Spinner 
                      variant="standard"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Sign In"
                  )
                }
              </Button>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </MainLayout>
  )
}


export default Login;