import React from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import { Row, Col, Button } from "react-bootstrap";

const Place = styled.div`
  text-align: center;
  img {
    border-radius: 5px;
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  b,
  p {
    ${({ font }) => font && `font-family: ${font};`}
  },
  background-image: url('https://iili.io/J12qAAl.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const MenuList = ({
  place,
  shoppingCart = {},
  onOrder,
  font = "",
  color = "",
}) => {
  const adsUrls = [
    {
      image: "https://iili.io/JXyT1Ev.jpg",
      url: "",
    },
    {
      image: "https://iili.io/JXyTahg.jpg",
      url: "",
    },
    {
      image: "https://iili.io/JXyT5p1.jpg",
      url: "",
    },
  ];

  const scrollToCategory = (categoryName) => {
    const categorySection = document.getElementById(categoryName);
    if (categorySection) {
      categorySection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container font={font} className="">
      <Row className="justify-content-between mt-5 ml-1 mr-1 align-items-end">
        <Col>
          <img src={place.image} width={150} height={150} alt={place.name} />
        </Col>
        <span className="text-right">
          <b
            style={{
              fontSize: 40,
              fontFamily: "arsenica",
              color: "white",
            }}
          >
            Le <br /> Menu
          </b>
        </span>
      </Row>
      <dix
        className="flex flex-nowrap ml-1 mr-1 mt-5 overflow-auto sticky top-5 z-10"
        style={{ gap: "2rem" }}
      >
        {place?.categories &&
          place.categories.map((item) => (
              <div key={item.id} className="flex flex-column items-center justify-content-center bg-white  rounded h-24 w-fit">
                  <h3

                      className="px-4 whitespace-nowrap"
                      onClick={() => scrollToCategory(item.name)}
                      style={{
                          cursor: "pointer",
                          fontFamily: "arsenica",
                          color: "black",
                          fontSize: 30,
                      }}
                  >
                      {item.name}
                  </h3>
              </div>
          ))}
      </dix>
        {place?.categories
            ?.filter(
                (category) => category.menu_items.filter((i) => i.is_available).length
            )
            .map((category, index) => (
                <div
                    key={category.id}
                    id={category.name}
                    className="mt-5 ml-3 mr-3"
                    style={{scrollMarginTop: "100px"}}
                >
                <h4 className="mb-4">
                    <b
                        style={{
                            fontFamily: "arsenica",
                            color: "white",
                            fontSize: 32,
                        }}
                    >
                        {category.name}
                    </b>
                </h4>
                {category.menu_items
                    .filter((item) => item.is_available)
                    .map((item) => (
                        <MenuItem
                            key={item.id}
                            item={{
                                ...item,
                                quantity: shoppingCart[item.id]?.quantity,
                            }}
                            onOrder={onOrder}
                            color={color}
                        />
                    ))}
                {/* Ads */}
                {(index + 1) % 3 === 0 && index / 3 < adsUrls.length && (
/*                    <div
                        className="mt-5 p-4"
                        onClick={() => {
                            if (adsUrls[Math.trunc(index / 3)].url) {
                                window.open(adsUrls[Math.trunc(index / 3)].url, "_blank");
                            }
                        }}
                        style={{
                            height: 300,
                            borderRadius: "1rem",
                                              backgroundImage: `url(${
                                                adsUrls[Math.trunc(index / 3)].image
                                              })`, // Specify the URL of your background image
                                                backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain", // Optional: Adjust background size as needed
                                              backgroundPosition: "center", // Optional: Adjust background position as needed
                        }}
                    >

                    </div>*/

                    <img
                        className="w-full py-4 pl-2 pr-4"
                        src={adsUrls[Math.trunc(index / 3)].image}
                        alt={"adsImage" + index}
                        style={{borderRadius: "2rem"}}
                    />


                )}
            </div>
            )
        )}
        <footer className="mt-5">
            <center>
                <Col>
                    <Col
                        className="justify-content-center mb-5"
                        style={{
                        width: "100%",
                        display: "flex-col",
                        justifyContent: "center",
                    }}
                >
              <span
                style={{
                  fontSize: 30,
                  fontFamily: "arsenica",
                  color: "white",
                }}
              >
                Probably
                <br />
                The Best Place
                <br />
                On Earth
              </span>
              <Row className="justify-content-center align-items-center">
                <div
                  className="w-20 h-1 bg-[#E83089]"
                  style={{
                    backgroundColor: "#E83089",
                    height: 2,
                    width: 80,
                  }}
                />
                <span
                  style={{
                    fontFamily: "jeanluc-bla",
                    fontSize: 22,
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    color: "#E83089",
                  }}
                >
                  SINCE 2013
                </span>
                <div
                  className="w-20 h-1 bg-[#E83089]"
                  style={{
                    backgroundColor: "#E83089",
                    height: 2,
                    width: 80,
                  }}
                />
              </Row>
              <span
                style={{
                  fontFamily: "jeanluc-bla",
                  fontSize: 22,
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  color: "white",
                }}
              >
                OUVERT TOUS LES JOURS À PARTIR DE 18H
              </span>
            </Col>
            {/* Second Footer Section */}
            <Col
              className="mt-2 mb-5 rounded justify-content-center p-4 bg-white"
              style={{
                width: "100%",
                display: "flex-col",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: 35,
                  fontFamily: "arsenica",
                }}
              >
                Suivez nous !
              </span>
              <Row
                className="justify-content-center align-items-center"
                style={{
                  gap: "2rem",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/codebarbeninofficiel/?locale=fr_FR"
                    );
                  }}
                  className="p-4"
                  style={{
                    height: 100,
                    width: 100,
                    background: "white",
                    borderRadius: "1rem",
                    backgroundImage: `url(https://cdn-icons-png.flaticon.com/512/747/747374.png)`, // Specify the URL of your background image
                    backgroundSize: "cover", // Optional: Adjust background size as needed
                    backgroundPosition: "center", // Optional: Adjust background position as needed
                  }}
                />
                <div
                  onClick={() => {
                    window.open("https://www.instagram.com/codebarbenin/");
                  }}
                  className="p-4"
                  style={{
                    height: 100,
                    width: 100,
                    background: "white",
                    borderRadius: "1rem",
                    backgroundImage: `url(https://cdn-icons-png.flaticon.com/512/1384/1384031.png)`, // Specify the URL of your background image
                    backgroundSize: "cover", // Optional: Adjust background size as needed
                    backgroundPosition: "center", // Optional: Adjust background position as needed
                  }}
                />
              </Row>
              <span
                style={{
                  fontFamily: "jeanluc-bla",
                  fontSize: 22,
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  color: "#E83089",
                }}
              >
                RÉSERVATIONS AUX
                <br /> 96 90 10 10 ET 45 45 97 97
              </span>
            </Col>
            <span
              style={{
                fontFamily: "jeanluc-bla",
                fontSize: 22,
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginBottom: "10rem",
                color: "white",
              }}
            >
              Powered by
              <br />
            </span>

            <div
              className="p-4"
              style={{
                height: 30,
                width: 150,
                backgroundRepeat: "no-repeat",
                borderRadius: "1rem",
                backgroundImage: `url(https://iili.io/J131exS.png)`, // Specify the URL of your background image
                backgroundSize: "contain", // Optional: Adjust background size as needed
                backgroundPosition: "center", // Optional: Adjust background position as needed
              }}
            />
          </Col>
        </center>
      </footer>
    </Container>
  );
};

export default MenuList;
