import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import React from 'react';
import MainLayout from '../layouts/MainLayout';

const Home = () => (
    <MainLayout>
      <div className="">
        <div className="p-5  bg-light rounded-3">
          <Container>
            <Row>
              <Col md={6} className="my-auto">
                <h1><b>QR Code Menu</b></h1>
                <h5 className="mt-4 mb-4">
                  Une façon intelligente de partager avec vos clients votre menu numérique sous forme de code QR
                </h5>
                <br/>
                <Button href="/places" variant="standard" size="lg">
                  Créer votre Menu
                </Button>
              </Col>
              <Col md={6}>
                <Image src="https://images.unsplash.com/photo-1427446473782-c2024dadf6a9?q=80&w=640&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                       rounded fluid="true"/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </MainLayout>
);

export default Home;