import { Route, Navigate } from 'react-router-dom';
import React, { useContext } from 'react';

import AuthContext from '../contexts/AuthContext';

function PrivateRoute({ element, ...rest }) {
  const auth = useContext(AuthContext);

/*  return (
    <Route 
      {...rest}
      render={({ location }) => 
        auth.token ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )*/

  return (
      auth.token ? element :           <Navigate to="/login" replace />
  );
}

export default PrivateRoute;